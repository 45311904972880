<template>
  <div>
    <van-list
      v-model="loading"
      :error.sync="listError"
      error-text="请求失败，点击重新加载"
      finished-text="到底了哦~"
      :finished="finished" @load="getList">

      <article-comment-list
        v-for="(item, idx) in dataList"
        :key="idx" :info="item"  @click.native="$router.push({ name: 'ArticleCommentDetailsPage', query: { articleId: item.id } })">
      </article-comment-list>
      
    </van-list>
  </div>
</template>

<script>
import to from "@/utils/to";
import { List } from "vant";
import { getArticleComments } from "@/api/mine";
import ArticleCommentList from "@/views/album/ArticleCommentList";
export default {
  components: {
    ArticleCommentList,
    [List.name]: List,
  },
  data() {
    return {
      loading: false,
      listError: false,
      finished: false,

      dataList: [],
      pageNum: 1,
    }
  },
  methods: {
    async getList() {
      let [err, res] = await to(getArticleComments(this.pageNum,15));
       if (err) {
         this.listError = true;
         return;
       }

       if (res.rows.length === 0) {
         this.finished = true;
         return;
       } 

       this.dataList = this.dataList.concat(res.rows);

       this.loading = false;
       this.pageNum = res.pageNum + 1;
    }
  }
}
</script>

<style scoped>

</style>