<template>
  <div class="article-box">
    <div class="article-title">{{ info.title }}</div>
    <div class="bottom-box">
      <div class="left-box">
        <div class="new-comment" v-if="unreadShow">
          新评论{{ info.unreadCommentNum }}条
        </div>
        <div class="article-info">评论数{{ info.commentNum }}.</div>
        <div class="article-info">精选数{{ info.selectedNum }}</div>
      </div>
      <span class="article-info">{{ info.createTime | dateFormat }}</span>
    </div>
    <van-divider />
  </div>
</template>

<script>
import { Divider } from "vant";
export default {
  components: {
    [Divider.name]: Divider,
  },
  props: {
    info: Object,
  },
  data() {
    return {
      unreadShow: false,
    };
  },
  mounted() {
    this.unreadShow = this.info.unreadCommentNum > 0 ? true : false;
  },
};
</script>

<style scoped>
.article-box {
  margin: 20px 16px 20px 16px;
}

.article-title {
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  color: #1a1a1a;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin-bottom: 12px;
}

.bottom-box {
  display: flex;
  justify-content: space-between;
}

.left-box {
  display: flex;
  justify-content: center;
}

.new-comment {
  border-radius: 4px;
  background: #ffc83e;
  width: 74px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  font-size: 12px;
  padding: 0px 6px 2px 6px;
  color: #333333;
}

.article-info {
  font-size: 14px;
  color: #999999;
  margin-left: 4px;
}
</style>